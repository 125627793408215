
import { getAdministratorRequest } from "@/sea/apis/AdministratorsService";
import { defineComponent, onMounted, ref } from "vue";
import { I18nUtils } from "@/sea/utils/I18nUtils";
import { useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import LightAlert from "@/sea/components/alerts/LightAlert.vue";

export default defineComponent({
  name: "administrator",
  components: { LightAlert },
  props: {
    id: Number,
  },

  setup(props) {
    const router = useRouter();

    const id = props.id ?? 0;
    const { errorRef, administratorRef, getAdministrator } =
      getAdministratorRequest();
    const loadingRef = ref(false);

    const fetchAdministrator = async (id: number) => {
      loadingRef.value = true;
      await getAdministrator(id);
      loadingRef.value = false;
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs(
        "sea.pages.administrator.title",
        [
          "sea.aside.categories.management.title",
          "sea.pages.administrators.title",
        ],
        ["", router.resolve({ name: "administrators" }).path]
      );
      await fetchAdministrator(id);
    });

    return {
      I18nUtils,
      loadingRef,
      administratorRef,
      errorRef,
    };
  },
});
